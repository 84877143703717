// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("/codebuild/output/src431342473/src/vad/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("/codebuild/output/src431342473/src/vad/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-privacyeu-js": () => import("/codebuild/output/src431342473/src/vad/src/pages/privacyeu.js" /* webpackChunkName: "component---src-pages-privacyeu-js" */),
  "component---src-pages-tou-js": () => import("/codebuild/output/src431342473/src/vad/src/pages/tou.js" /* webpackChunkName: "component---src-pages-tou-js" */),
  "component---src-pages-upload-js": () => import("/codebuild/output/src431342473/src/vad/src/pages/upload.js" /* webpackChunkName: "component---src-pages-upload-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/codebuild/output/src431342473/src/vad/.cache/data.json")

